/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'caret-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10 12.796V3.204L4.519 8zm-.659.753l-5.48-4.796a1 1 0 010-1.506l5.48-4.796A1 1 0 0111 3.204v9.592a1 1 0 01-1.659.753"/>',
    },
});
